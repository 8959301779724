import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col, Carousel, Image, Space } from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            lg: dataSource.imgWrapper.lg,
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          
          {/* <Carousel arrows dotPosition="left" infinite={false}  style={{borderRadius: 8}}> */}
          {/* <Space direction="horizontal" > */}
            {/* <Image src="images/02.png" style={contentStyle}/> */}
            {/* <Image src="images/03.png" style={contentStyle}/> */}
            {/* <Image width={200} src="images/04.png" style={contentStyle}/> */}
          {/* </Space> */}
          {/* </Carousel> */}
          
          <span {...dataSource.img}>
            <img src={dataSource.img.children} width="100%" alt="img" />   
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          <br />
          <div direction="horizontal" size="large">
            <div key="p" {...dataSource.content} style={{float: "left", margin: "10px"}}>
              <img src={dataSource.badges.ios.img} width={260} height={80} style={{cursor: "pointer"}} onClick={() => {window.open("https://apple.co/3LbibQ9", "_blank")}}/>
            </div>
           
            <div key="p" {...dataSource.content} style={{float: "left",  margin: "10px"}}>
              <img src={dataSource.badges.android.img} width={270} height={80}  style={{cursor: "pointer"}} onClick={() => {window.open("https://bit.ly/4cOB2wL", "_blank")}}/>
            </div>
          </div>
          
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
